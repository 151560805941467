import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import "./style.scss";
import CardCreator from "./pages/CardCreator";
import NotFound from "./pages/NotFound";
import MainLayout from "./layouts/MainLayout";
import CreateLayout from "./layouts/CreateLayout";
import Login from "./pages/Login";
import ProfileLayout from "./layouts/ProfileLayout";
import Signup from "./pages/Signup";
import AuthProvider, { AuthContext } from "./contexts/AuthContext";
import CardsPage from "./pages/CardsPage";
import Bills from "./pages/Bills";
import CardPublishedPage from "./pages/CardPublishedPage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/login"
            element={
              <MainLayout>
                <Login />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/signup"
            element={
              <MainLayout>
                <Signup />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/create"
            element={
              <CreateLayout>
                <CardCreator />
              </CreateLayout>
            }
          ></Route>
          <Route
            exact
            path="/cards"
            element={
              <ProfileLayout>
                <CardsPage />
              </ProfileLayout>
            }
          ></Route>
          <Route
            exact
            path="/cards/:id"
            element={<CardPublishedPage />}
          ></Route>
          <Route
            path="/bills"
            element={
              <ProfileLayout>
                <Bills />
              </ProfileLayout>
            }
          ></Route>
          <Route
            path="*"
            element={
              <MainLayout>
                <NotFound />
              </MainLayout>
            }
          ></Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

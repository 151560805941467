import axios from "axios";

import { KANDINSKY_URL } from "../config";

export const getTextByPrompt = async (text, specials) => {
  try {
    let res = await axios.get(
      KANDINSKY_URL +
        "/gigachat/say/?message=" +
        text +
        "&specials=" +
        specials,
      {},
      { headers: {} }
    );

    return await res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

import React, { createContext, useEffect, useState } from "react";
import app from "../data/firebase";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthLoading, setisAuthLoading] = useState(true);
  const [username, setUsername] = useState("Профиль");

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        await app
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setUsername(doc.data().name);
            }
          });
        if (!user.emailVerified) {
          user.sendEmailVerification();
        }
      }

      setisAuthLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isAuthLoading, username }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

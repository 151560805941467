import React, { useContext, useEffect, useState } from "react";
import { IoAddCircle } from "react-icons/io5";
import { constants } from "./../helpers/constants";
import Dropdown from "./../components/Dropdown";
import Block from "../components/editor/Block";
import { getRandomColor, getRandomInt, guid } from "../helpers/utils";
import Modal from "./../components/Modal";
import { AuthContext } from "../contexts/AuthContext";
import { Redirect, useParams, useLocation, useHistory } from "react-router";
import { useNavigate, Navigate } from "react-router-dom";
import app from "./../data/firebase";
import BordersContext from "../contexts/BordersContext";
import { useBeforeunload } from "react-beforeunload";

let phrases = [
  "Поздравляем с Днем Рождения!",
  "От души желаю счастья!",
  "Хорошего дня!",
  "Плодотворной работы!",
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CardCreator = () => {
  const { id } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const [blocks, setBlocks] = useState([]);
  const [borders, setBorders] = useState(constants.BORDERS);
  const [cardId, setCardId] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [name, setName] = useState("Новая открытка");
  const [prevName, setPrevName] = useState("Новая открытка");
  const [nameChanged, setNameChanged] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alteredBlocks, setAlteredBlocks] = useState([]);

  let { currentUser, isAuthLoading } = useContext(AuthContext);
  useBeforeunload((e) => {
    onLeavingPage(e);
  });

  useEffect(() => {
    let cardId = query.get("id");
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#fff");
    (async () => {
      if (cardId) {
        setCardId(cardId);
        let cardData = await getCardData(cardId);
        setBlocks(cardData.blocks);
        setCreatorId(cardData.userId);
        setName(cardData.name);
        setPrevName(cardData.name);
      } else {
        cardId = guid();
        setCardId(cardId);
      }

      let newBorders = await getBorders();
      console.log(newBorders);
      setBorders((prev) => [...prev, ...newBorders]);
    })();
  }, []);

  useEffect(() => {
    if (currentUser && creatorId) {
      if (currentUser.uid !== creatorId) {
        navigate("/");
      }
    }
  }, [currentUser, creatorId]);

  const getCardData = async (id) => {
    let cardData = await app
      .firestore()
      .collection("cards")
      .doc(id)
      .get()
      .then((doc) => doc.data());
    return cardData;
  };

  const onLeavingPage = (e) => {
    if (alteredBlocks.length !== 0 || prevName !== name) {
      e.preventDefault();
      window.confirm("Sheeh");
      console.log("SHEEEESH");
      e.returnValue = "Bsssss";
      return "SHEESh";
    }
  };

  const saveCard = async () => {
    setLoading(true);
    try {
      let data = { cardId, blocks, userId: currentUser.uid, name };
      let exists = (await app.firestore().collection("cards").doc(cardId).get())
        .exists;
      console.log(exists);
      if (!exists) {
        await app.firestore().collection("cards").doc(cardId).set(data);
      } else {
        await app.firestore().collection("cards").doc(cardId).update(data);
      }
    } catch (error) {
      console.log(error);
    }
    await processChanges();
    setPrevName(name);
    setLoading(false);
  };

  const getBorders = async () => {
    let borders = await app
      .firestore()
      .collection("borders")
      .get()
      .then((qs) => {
        let data = [];
        qs.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return data;
      });

    return borders;
  };

  const onAddClicked = (id) => {
    let block = addBlock(id);
    console.log("Altered: " + block);
    handleChanges(block, "add");
  };

  const addBlock = (typeId) => {
    let id = guid().toString();
    let blockData = {};
    setBlocks((prev) => {
      let block = {
        id: id,
        type: typeId,
        images: [],
        videoUrl: "",
        audioUrl: "",
        backgroundColor:
          prev.length > 0
            ? prev[prev.length - 1].backgroundColor
            : getRandomColor(),
        header: phrases[getRandomInt(0, phrases.length)],
        text: "Текст для вашего поздравления",
        headerColor: "#000",
        textColor: prev.length > 0 ? prev[prev.length - 1].textColor : "#000",
        textSize: 18,
        headerSize: typeId === 4 || typeId === 3 ? 32 : 24,
        headerWeight: 600,
        audioName: "",
        fontFamily:
          prev.length > 0
            ? prev[prev.length - 1].fontFamily
            : constants.FONTS[getRandomInt(0, constants.FONTS.length)],
      };
      let newBlocks = [...prev, block];
      blockData = block;
      return newBlocks;
    });
    return blockData;
  };

  const onBlockDeleted = async (id) => {
    console.log(id);
    let blockData = blocks.find((b) => b.id === id);
    handleChanges(blockData, "delete");
    setBlocks((prev) => prev.filter((b) => b.id !== id));
  };

  const onBlockUpdated = (data) => {
    setBlocks((prev) => {
      let blockIdx = prev.findIndex((b) => b.id == data.id);
      let bs = [...prev];
      bs[blockIdx] = data;
      return bs;
    });
    handleChanges(data, "update");
  };

  const swapBlockUp = (id) => {
    let blockIdx = blocks.findIndex((b) => b.id == id);
    if (blockIdx == 0) {
      return;
    }
    setBlocks((prev) => {
      let arr = [...prev];
      [arr[blockIdx - 1], arr[blockIdx]] = [arr[blockIdx], arr[blockIdx - 1]];
      handleChanges(arr[blockIdx], "swapUp");
      return arr;
    });
  };

  const swapBlockDown = (id) => {
    let blockIdx = blocks.findIndex((b) => b.id == id);
    if (blockIdx == blocks.length - 1) {
      return;
    }
    setBlocks((prev) => {
      let arr = [...prev];
      [arr[blockIdx], arr[blockIdx + 1]] = [arr[blockIdx + 1], arr[blockIdx]];
      handleChanges(arr[blockIdx], "swapDown");
      return arr;
    });
  };

  const onPublishPressed = async () => {
    console.log(blocks);
  };

  const handleChanges = (block, type) => {
    setAlteredBlocks((prev) => {
      let changes = [...prev];
      changes.push({ type, block });
      return changes;
    });
  };

  const processChanges = async () => {
    for (const alteration of alteredBlocks) {
      if (alteration.type === "delete") {
        //delete all images from firebase storage
        for (const img of alteration.block.images) {
          console.log("Deleted: " + img);
          try {
            await app.storage().ref(`/cards_images/${img.name}`).delete();
          } catch (error) {
            console.log(error);
          }
        }
        if (alteration.block.audioName) {
          await app
            .storage()
            .ref(`/audio_files/${alteration.block.audioName}`)
            .delete();
        }
      }
    }
    setAlteredBlocks([]);
  };

  if (isAuthLoading) return <div>Загрузка...</div>;

  if (!currentUser) {
    return <Navigate replace to="/login" />;
  }

  // if (currentUser.uid !== creatorId) {
  //   return <Redirect to="/" />;
  // }

  return (
    <BordersContext.Provider value={borders}>
      <div>
        <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/#">
                    Главная
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href={`/cards/${cardId}`}
                  >
                    Просмотр
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/cards">
                    Мои поздравления
                  </a>
                </li>
                <li className="nav-item px-2">
                  <input
                    className="form-control"
                    type={"text"}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      console.log(name == prevName);
                    }}
                  />
                </li>
                <li className="nav-item">
                  <button
                    className={`btn ${loading && "btn-secondary"} ${
                      !loading && alteredBlocks.length === 0 && prevName == name
                        ? "btn-secondary"
                        : "btn-success"
                    }`}
                    onClick={saveCard}
                    disabled={
                      loading ||
                      (alteredBlocks.length === 0 && prevName == name)
                    }
                  >
                    {loading
                      ? "Сохранение..."
                      : !loading &&
                        alteredBlocks.length === 0 &&
                        prevName == name
                      ? "Сохранено"
                      : "Сохранить"}
                  </button>
                  {loading && (
                    <img src="./spinner.svg" height="40px" width="40px" />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="d-flex flex-column pt-5">
          <div className="d-flex flex-column align-items-center">
            {blocks.map((block, i) => {
              return (
                <Block
                  data={block}
                  key={block.id}
                  onDeleted={onBlockDeleted}
                  onBlockUpdated={onBlockUpdated}
                  onBlockSwappedDown={swapBlockDown}
                  onBlockSwappedUp={swapBlockUp}
                />
              );
            })}
            <button
              type="button"
              className="btn btn-outline-light"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <IoAddCircle size={36} color={constants.PRIMARY_COLOR} />
            </button>
            <p className="fw-bold text-secondary">Добавить блок</p>
            <Modal title={"Выберите шаблон"}>
              <div className="container-fluid p-3">
                <div className="row">
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(1);
                      }}
                    >
                      <img src="../assets/t_p_left.png" width={"300px"} />
                      Текст с фотографией слева
                    </a>
                  </div>
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(2);
                      }}
                    >
                      <img src="../assets/t_p_right.png" width={"300px"} />
                      Текст с фотографией справа
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(3);
                      }}
                    >
                      <img src="../assets/t_h.png" width={"300px"} />
                      Заголовок с текстом
                    </a>
                  </div>
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(4);
                      }}
                    >
                      <img src="../assets/h.png" width={"300px"} />
                      Заголовок
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-6  p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(5);
                      }}
                    >
                      <img src="../assets/one_pic.png" width={"300px"} />
                      Одна фотография
                    </a>
                  </div>
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(6);
                      }}
                    >
                      <img src="../assets/two_pic.png" width={"300px"} />
                      Две фотографии
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(7);
                      }}
                    >
                      <img src="../assets/three_pic.png" width={"300px"} />
                      Три фотографии
                    </a>
                  </div>
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(8);
                      }}
                    >
                      <img src="../assets/yt.png" width={"300px"} />
                      Видео YouTube
                    </a>
                  </div>
                </div>
                <div className="row">
                  {blocks.findIndex((b) => b.type === 9) === -1 && (
                    <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                      <a
                        className="btn btn-secondary d-flex flex-column"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          onAddClicked(9);
                        }}
                      >
                        <img src="../assets/music.png" width={"300px"} />
                        Своя аудиодорожка
                      </a>
                    </div>
                  )}
                  <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn btn-secondary d-flex flex-column"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        onAddClicked(10);
                      }}
                    >
                      <img src="/sticker_gift.webp" width={"300px"} />
                      Подарок
                    </a>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </BordersContext.Provider>
  );
};

export default CardCreator;

import React from "react";
import { IoLogoInstagram, IoMail } from "react-icons/io5";
const version = require("../../package.json").version;

const Footer = () => {
  return (
    <footer class="text-center text-lg-start bg-light text-muted">
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div class="me-5 d-none d-lg-block">
          <span>Оставайтесь с нами на связи в социальных сетях:</span>
        </div>

        <div>
          <a href="https://instagram.com/bonfetta/" class="text-reset">
            <IoLogoInstagram size={32} />
          </a>
        </div>
      </section>
      <section class="">
        <div class="container text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <img
                className="mb-4"
                src="./bonfetta_logo.svg"
                height="30px"
                style={{ filter: "grayscale", filter: "brightness(50%)" }}
              />
              <p>Сервис цифровых поздравлений в виде сайтов</p>
            </div>

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">Cсылки</h6>
              <p>
                <a href="/" class="text-reset text-decoration-none">
                  Главная
                </a>
              </p>
              <p>
                <a href="#about" class="text-reset text-decoration-none">
                  О проекте
                </a>
              </p>
              <p>
                <a href="/login" class="text-reset text-decoration-none">
                  Вход
                </a>
              </p>
              {/* <p>
                <a href="/" class="text-reset">
                  Поддержка
                </a>
              </p> */}
            </div>
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 class="text-uppercase fw-bold mb-4">Контакты</h6>

              <p>
                <IoMail /> support@bonfetta.com
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        class="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <p className="text-center text-muted">© 2021 Bonfetta, v{version}</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useContext } from "react";
import Image from "./../editor/Image";
import sticker from "../../assets/sticker_gift.webp";
import BlocksContext from "../../contexts/BlocksContext";

//one image
const GiftBlock = ({
  blockData = {},
  editing = true,
  onImagePicked = () => {},
  giftData = {},
}) => {
  const { onGiftClicked } = useContext(BlocksContext);

  return (
    <div className="row container-fluid">
      <div className="col-12 d-flex justify-content-center">
        <a
          href="javascript:void(0)"
          onClick={() => {
            onGiftClicked();
          }}
        >
          <img className="gift-image" src={sticker} />
          <h2 className="text-center">
            {editing && !blockData.gift && "Выберите подарок для адресата"}
            {editing && blockData.gift && "Подарок выбран"}
            {!editing && "Вам подарок"}
          </h2>
        </a>
      </div>
    </div>
  );
};

export default GiftBlock;

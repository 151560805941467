import React, { useEffect, useState, useRef } from "react";
import {
  IoArrowDown,
  IoArrowUp,
  IoImageOutline,
  IoText,
  IoTrashBin,
} from "react-icons/io5";
import { constants } from "../../helpers/constants";
import { guid } from "../../helpers/utils";
import BlockType1 from "../blocks/BlockType1";
import BlockType2 from "../blocks/BlockType2";
import BlockType3 from "../blocks/BlockType3";
import BlockType4 from "../blocks/BlockType4";
import BlockType5 from "../blocks/BlockType5";
import BlockType6 from "../blocks/BlockType6";
import BlockType7 from "../blocks/BlockType7";
import BlockTypeAudio from "../blocks/BlockTypeAudio";
import BlockTypeYouTube from "../blocks/BlockTypeYouTube";
import EditableText from "./EditableText";
import app from "./../../data/firebase";
import BlocksContext from "../../contexts/BlocksContext";
import imageCompression from "browser-image-compression";
import { Modal, Spinner } from "react-bootstrap";
import { getTextByPrompt } from "../../shared/api/gigachatAPI";
import GiftBlock from "../blocks/GiftBlock";
import { GIFTS } from "../../shared/config";

const Block = ({
  data,
  editing = true,
  onDeleted = (id) => {},
  onBlockUpdated = (data) => {},
  onBlockSwappedUp = () => {},
  onBlockSwappedDown = () => {},
}) => {
  const [blockData, setBlockData] = useState({});
  const colorInputRef = useRef(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [imageLoadingNumber, setImageLoadingNumber] = useState(0);

  const [showModalGenerateText, setShowModalGenerateText] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [generationLoading, setGenerationLoading] = useState(false);

  const [generatedText, setGeneratedText] = useState("");
  const [generatedTextExample, setGeneratedTextExample] = useState("");

  const [giftChooseModal, setGiftChooseModal] = useState(false);
  const [giftModal, setGiftModal] = useState(false);

  useEffect(() => {
    setBlockData(data);
  }, []);

  const onImagePicked = async (event, imageNumber, base64) => {
    console.log("IMAGE PICKED");
    console.log(imageNumber);
    if (base64) {
      let block = { ...data };
      let idx = block.images.findIndex(
        (img) => img.imageNumber === imageNumber
      );
      let name = `${guid()}`; //???

      if (idx === -1) {
        block.images.push({
          imageNumber,
          uri: "data:image/jpeg;base64," + base64,
          name: name,
        });
      } else {
        block.images[idx].uri = "data:image/jpeg;base64," + base64;
      }

      onBlockUpdated(block);

      return;
    }

    if (event.target.files && event.target.files[0]) {
      setImageLoadingNumber(imageNumber);
      setImageLoading(true);
      let name = `${guid()}`; //???
      let block = { ...data };
      let idx = block.images.findIndex(
        (img) => img.imageNumber === imageNumber
      );
      let image = event.target.files[0];
      if (image.size / 1024 / 1024 > 6 && image.type === "image/gif") {
        alert("Размер gif-файла слишком большой. Максимальный размер- 6 МБ");
        setImageLoading(false);
        return;
      }
      if (image.size / 1024 / 1024 > 1 && image.type !== "image/gif") {
        image = await imageCompression(image, {
          maxSizeMB: 1,
        });
      }

      if (idx !== -1) {
        name = block.images[idx].name;
        await app.storage().ref(`/cards_images/${name}`).delete();
      }

      await app
        .storage()
        .ref(`/cards_images/${name}`)
        .put(image)
        .on(
          "state_changed",
          (snapShot) => {
            console.log(snapShot);
          },
          (err) => {
            console.log(err);
          },
          () => {
            app
              .storage()
              .ref("cards_images")
              .child(name)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                if (idx === -1) {
                  block.images.push({
                    imageNumber,
                    uri: fireBaseUrl,
                    name: name,
                  });
                } else {
                  block.images[idx].uri = fireBaseUrl;
                }
                onBlockUpdated(block);
                setImageLoading(false);
              });
          }
        );
    }
  };

  //implement later
  const onImageBorderChanged = async (borderData, imageNumber = 0) => {
    let block = { ...data };
    block.images[imageNumber].border = borderData;
    onBlockUpdated(block);
  };

  const onAudioPicked = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setAudioLoading(true);
      let name = guid();
      let block = { ...data };
      let uploadTask = await app
        .storage()
        .ref(`/audio_files/${name}`)
        .put(event.target.files[0])
        .on(
          "state_changed",
          (snapShot) => {
            console.log(snapShot);
          },
          (err) => {
            console.log(err);
          },
          () => {
            app
              .storage()
              .ref("audio_files")
              .child(name)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                block.audioUrl = fireBaseUrl;
                block.audioName = name;
                onBlockUpdated(block);
                setAudioLoading(false);
              });
          }
        );
    }
  };

  const onTextBlockTextChanged = (type, text) => {
    setGeneratedText("");
    let block = { ...data };
    if (type == "header") {
      block.header = text;
      onBlockUpdated(block);
    } else {
      block.text = text;
      onBlockUpdated(block);
    }
  };

  const onTextColorChanged = (color) => {
    let block = { ...data };
    block.textColor = color;
  };

  const onFontFamilyChanged = (family) => {
    let block = { ...data };
    block.fontFamily = family;
    onBlockUpdated(block);
  };

  const onVideoPicked = (url) => {
    let block = { ...data };
    block.videoUrl = url;
    onBlockUpdated(block);
  };

  const onFontSizeChanged = (type = "text", size) => {
    let block = { ...data };
    if (type === "text") {
      block.textSize = size;
    } else {
      block.headerSize = size;
    }

    onBlockUpdated(block);
  };

  const onGenerateTextClicked = async () => {
    setGenerationLoading(true);
    let data = await getTextByPrompt(prompt, additionalInfo);

    console.log(data);
    setGeneratedTextExample(data.message);
    setGenerationLoading(false);
  };

  const onGiftClicked = () => {
    if (editing) {
      setGiftChooseModal(true);
      return;
    }

    setGiftModal(true);
  };

  return (
    <BlocksContext.Provider
      value={{
        onImagePicked,
        onAudioPicked,
        onTextBlockTextChanged,
        onVideoPicked,
        onImageBorderChanged,
        setShowModalGenerateText,
        onGiftClicked,
        loadingImage: { imageLoadingNumber, imageLoading },
        audioLoading: audioLoading,
        generatedText,
      }}
    >
      <div
        className="container-fluid d-flex block flex-column  align-items-center justify-content-center"
        style={{ backgroundColor: data.backgroundColor }}
      >
        {editing && (
          <div className="d-flex justify-content-start container-fluid mb-3">
            <div className="instruments-container row border flex-row rounded justify-content-start">
              <div className="d-flex col p-0 flex-row flex-wrap ">
                <a
                  className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0"
                  onClick={() => {
                    onBlockSwappedDown(data.id);
                  }}
                >
                  <IoArrowDown size={16} />
                </a>
                <a
                  className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0 "
                  onClick={() => {
                    onBlockSwappedUp(data.id);
                  }}
                >
                  <IoArrowUp size={16} />
                </a>
                <input
                  className="btn btn-sm btn-outline-dark p-1 editor-control-sm instrument-button"
                  value={data.backgroundColor}
                  type="color"
                  onChange={(e) => {
                    console.warn(e.target.value);
                    let block = { ...data };
                    block.backgroundColor = e.target.value;
                    onBlockUpdated(block);
                  }}
                />

                {/* EXPERIMENTAL */}
                <a
                  className="btn btn-sm btn-outline-dark d-flex align-items-center justify-content-center flex-column instrument-button editor-control-sm p-1"
                  onClick={() => {
                    colorInputRef.current.click();
                  }}
                >
                  <IoText size={16} />
                  <div
                    style={{
                      backgroundColor: data.textColor,
                      height: "5px",
                      width: "100%",
                    }}
                  ></div>
                  <input
                    ref={colorInputRef}
                    value={data.textColor}
                    style={{ visibility: "hidden", position: "absolute" }}
                    type="color"
                    onChange={(e) => {
                      let block = { ...data };
                      block.textColor = e.target.value;
                      onBlockUpdated(block);
                    }}
                  />
                </a>
                <div className="dropdown instrument-button">
                  <a
                    className="btn btn-sm btn-outline-dark dropdown-toggle"
                    style={{ fontFamily: data.fontFamily }}
                    id="blockDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {data.fontFamily}
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{
                      height: "200px",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                    aria-labelledby="blockDropdown"
                  >
                    {constants.FONTS.map((f, i) => {
                      return (
                        <li>
                          <a
                            className="dropdown-item"
                            style={{ fontFamily: f }}
                            onClick={() => onFontFamilyChanged(f)}
                          >
                            {f}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="dropdown instrument-button">
                  <a
                    className="btn btn-sm btn-outline-dark dropdown-toggle"
                    id="blockDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Заголовок: {data.headerSize}
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{
                      height: "200px",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                    aria-labelledby="blockDropdown"
                  >
                    {constants.FONT_SIZES.map((fs, i) => {
                      return (
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => onFontSizeChanged("header", fs)}
                          >
                            {fs}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="dropdown instrument-button">
                  <a
                    className="btn btn-sm btn-outline-dark dropdown-toggle"
                    id="blockDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Текст: {data.textSize}
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{
                      height: "200px",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                    aria-labelledby="blockDropdown"
                  >
                    {constants.FONT_SIZES.map((fs, i) => {
                      return (
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => onFontSizeChanged("text", fs)}
                          >
                            {fs}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <a
                  className="btn btn-sm btn-outline-danger instrument-button"
                  onClick={() => {
                    onDeleted(blockData.id);
                  }}
                >
                  <IoTrashBin size={16} />
                </a>
              </div>
            </div>
          </div>
        )}
        {data.type == 1 && (
          <BlockType1
            editing={editing}
            blockData={data}
            onTextChanged={onTextBlockTextChanged}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 2 && (
          <BlockType2
            editing={editing}
            blockData={data}
            onTextChanged={onTextBlockTextChanged}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 3 && (
          <BlockType3
            editing={editing}
            blockData={data}
            onTextChanged={onTextBlockTextChanged}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 4 && (
          <BlockType4
            editing={editing}
            blockData={data}
            onTextChanged={onTextBlockTextChanged}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 5 && (
          <BlockType5
            editing={editing}
            blockData={data}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 6 && (
          <BlockType6
            editing={editing}
            blockData={data}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 7 && (
          <BlockType7
            editing={editing}
            blockData={data}
            onImagePicked={onImagePicked}
          />
        )}
        {data.type == 8 && (
          <BlockTypeYouTube
            editing={editing}
            blockData={data}
            onVideoPicked={onVideoPicked}
          />
        )}
        {data.type == 9 && (
          <BlockTypeAudio
            editing={editing}
            blockData={data}
            onAudioPicked={onAudioPicked}
            audioLoading={audioLoading}
          />
        )}

        {data.type == 10 && <GiftBlock editing={editing} blockData={data} />}
      </div>

      <Modal show={showModalGenerateText} size={"lg"}>
        <Modal.Header>
          <strong>Генерация текста поздравления</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setGeneratedText("");
              setShowModalGenerateText(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-4  g-4 p-4">
            <div>
              <h5>Введите запрос:</h5>

              <textarea
                className="w-100 p-2"
                placeholder={`Например: "Поздравление для коллеги с днем пива"`}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />

              <h6 className="mt-2">
                Особые факты, которые помогут сделать более уникальное
                поздравление:
              </h6>
              <textarea
                className="w-100 p-2"
                placeholder={`Например: "Он любит футбол и играть в компьютер"`}
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
              />
            </div>
            <div>
              <h5>Результат:</h5>
              <p>
                {generatedTextExample || "Здесь появится сгенерированный текст"}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {generationLoading ? (
            <Spinner />
          ) : (
            <button
              className={`btn ${
                prompt ? "btn-primary" : "btn-secondary text-black"
              } rounded-pill`}
              onClick={() => {
                onGenerateTextClicked();
              }}
              disabled={!prompt}
            >
              Сгенерировать
            </button>
          )}

          {generatedTextExample && (
            <button
              className={`btn btn-success rounded-pill`}
              onClick={() => {
                setGeneratedText("");
                setGeneratedText(generatedTextExample);
                setShowModalGenerateText(false);
                // setGeneratedText("");
              }}
            >
              Готово
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={giftChooseModal} size={"lg"}>
        <Modal.Header>
          <strong>Выберите подарок</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setGeneratedText("");
              setGiftChooseModal(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="gifts-wrapper gap-4  g-4 p-4">
            {GIFTS.map((gift, i) => (
              <div
                key={"gift-i"}
                className="d-flex justify-content-center align-items-center"
              >
                <a
                  className="btn btn-secondary d-flex flex-column"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setGiftChooseModal(false);
                    let block = { ...data };
                    block.gift = gift;

                    onBlockUpdated(block);
                  }}
                >
                  <img src={gift.thumb} width={"300px"} />
                  <h5>{gift.name}</h5>

                  <h5>{gift.price}р.</h5>
                </a>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {generationLoading ? (
            <Spinner />
          ) : (
            <button
              className={`btn ${
                prompt ? "btn-primary" : "btn-secondary text-black"
              } rounded-pill`}
              onClick={() => {
                onGenerateTextClicked();
              }}
              disabled={!prompt}
            >
              Сгенерировать
            </button>
          )}

          {generatedTextExample && (
            <button
              className={`btn btn-success rounded-pill`}
              onClick={() => {
                setGeneratedText("");
                setGeneratedText(generatedTextExample);
                setShowModalGenerateText(false);
                // setGeneratedText("");
              }}
            >
              Готово
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={giftModal} size={"lg"}>
        <Modal.Header>
          <strong>Выберите подарок</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setGeneratedText("");
              setGiftModal(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center gap-4  g-4 p-4">
            <h2 className="font-bold">Ваш подарок:</h2>
            <div className="text-center">
              <img
                src={data?.gift?.thumb}
                className="w-100 object-fit-contain"
              />
              <h3>{data?.gift?.name}</h3>
              <p>{data?.gift?.description}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`btn btn-success rounded-pill`}
            onClick={() => {
              setGiftModal(false);
              // setGeneratedText("");
            }}
          >
            Готово
          </button>
        </Modal.Footer>
      </Modal>
    </BlocksContext.Provider>
  );
};

export default Block;

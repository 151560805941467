import React, { useContext } from "react";
import { Redirect, useHistory } from "react-router";
import { Navigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { AuthContext } from "../contexts/AuthContext";
import app from "../data/firebase";
import { toast, ToastContainer } from "react-toastify";

const Login = () => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Navigate replace to="/cards" />;
  }

  return (
    <div className="container py-5">
      <div className="d-flex align-items-center flex-column mt-5">
        <h2 className="header">Вход</h2>
        <div className="col-lg-6 col-12">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useRef, useEffect, useContext } from "react";
import { IoScanOutline, IoSparkles } from "react-icons/io5";
import BlocksContext from "../../contexts/BlocksContext";

const EditableText = ({
  placeholder,
  editing = true,
  text,
  fontSize = 16,
  onTextChanged = (e) => {},
  fontWeight = "normal",
  type,
  color = "#000",
  fontFamily = "Pacifico",
  only = false,
}) => {
  const { setShowModalGenerateText, generatedText } = useContext(BlocksContext);

  const contentRef = useRef();
  const [textContent, setTextContent] = useState("");

  useEffect(() => {
    contentRef.current.innerText = text;
  }, []);

  useEffect(() => {
    if (type === "text" || only) {
      contentRef.current.innerText = generatedText || text;
      onTextChanged(type, contentRef.current.innerText);
    }
  }, [generatedText]);

  return (
    <div
      className="container-fluid position-relative text-center editor-input"
      style={{ border: "none" }}
    >
      {editing && (type === "text" || only) && (
        <a
          className="btn btn-sm btn-light instrument-button editor-control-sm p-0 m-0 my-1 position-absolute text-ai-btn"
          onClick={() => {
            setShowModalGenerateText(true);
          }}
          title="Рамка"
        >
          <IoSparkles size={18} />
        </a>
      )}
      <div
        ref={contentRef}
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: color,
          fontFamily: fontFamily,
        }}
        contentEditable={editing}
        onInput={(e) => {
          setTextContent(e.target.innerText);
          onTextChanged(type, e.target.innerText);
        }}
        onChange={(e) => {
          setTextContent(e.target.innerText);
          onTextChanged(type, e.target.innerText);
        }}
      ></div>
    </div>
  );
};

export default EditableText;

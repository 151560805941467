import React, { useEffect, useState } from "react";
import { fadeIn, fadeOutUp, rotateIn } from "react-animations";
import { useSpring, animated } from "react-spring";

const CardPreview = ({
  background = "",
  fontFamily = "",
  fontColor = "",
  onButtonPressed = () => {},
  from = "",
  to = "",
  isReady = true,
}) => {
  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.margin = "0";
    document.body.style.height = "100%";
    return () => {
      document.body.style.overflow = null;
      document.body.style.margin = null;
      document.body.style.height = null;
    };
  }, []);

  const props = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    pause: !animation,
    config: {
      duration: 500,
    },
  });

  return (
    <animated.div
      style={props}
      className="d-flex position-absolute h-100 vw-100 primary-bg justify-content-center  align-items-center p-3"
    >
      <div className="d-flex flex-column justify-content-between text-center text-white h-100">
        <div className="my-4">
          <a href="/">
            <img src="../bonfetta_logo.svg" height="100px" />
          </a>
        </div>
        <div className="d-flex flex-column justify-content-center h-100">
          <h1 className="header">Вам пришло поздравление!</h1>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {isReady ? (
              <button
                onClick={() => {
                  setAnimation(true);
                  onButtonPressed();
                }}
                className="btn btn-lg btn-light my-3 rounded-pill"
              >
                Открыть!
              </button>
            ) : (
              <img src="../spinner.svg" height="50px" />
            )}
            <p className="header">Скорее посмотрите, как вас поздравили!</p>
          </div>
          <div className="text-left">
            <p className="address-text">
              От: <span>{isReady && (from || "Меня")}</span> <br /> Кому:{" "}
              <span>{isReady && (to || "Тебе")}</span>
            </p>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default CardPreview;

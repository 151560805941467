import React, { useEffect } from "react";
import { getRandomColor } from "../../helpers/utils";
import { constants } from "./../../helpers/constants";

const BorderTypeCard = ({
  borderData = {},
  onClick = () => {},
  imageUri = "",
}) => {
  const styles = {
    solidBorder: {
      borderColor: getRandomColor(),
      borderWidth: borderData.width,
      borderStyle: "solid",
    },
    gradientBorder: {
      border: "30px solid",
      borderImage: `linear-gradient(${getRandomColor()}, ${getRandomColor()}) 30`,
    },
  };
  useEffect(() => {}, []);

  return (
    <a
      className="btn btn-light d-flex flex-column justify-content-between align-items-center h-100 w-100"
      onClick={() => onClick(borderData)}
    >
      <div className="d-flex justify-content-center align-items-center h-100">
        <div
          className={`bg-white rounded`}
          style={{
            ...(borderData.type === "image" && {
              border: borderData.border,
              borderImage: borderData.borderImage,
            }),
            ...(borderData.type === "solid" && styles.solidBorder),
            ...(borderData.type === "gradient" && styles.gradientBorder),
            backgroundImage: `url(${imageUri})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div style={{ width: "100px", height: "100px" }}></div>
        </div>
      </div>
      {borderData.label}
    </a>
  );
};

export default BorderTypeCard;

import React, { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";

const CreateLayout = ({ children }) => {
  const context = useContext(AuthContext);

  return (
    <div>
      <div>{children}</div>
    </div>
  );
};

export default CreateLayout;

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  IoImageOutline,
  IoScan,
  IoScanOutline,
  IoSparkles,
  IoSquareOutline,
  IoTabletLandscapeOutline,
} from "react-icons/io5";
import BlocksContext from "../../contexts/BlocksContext";
import BordersContext from "../../contexts/BordersContext";
import { constants } from "../../helpers/constants";
import BorderTypeCard from "./BorderTypeCard";
import {
  getImageGenerationStatus,
  getKandinskyModels,
  getKandinskyPossibleStyles,
  postText2Image,
} from "../../shared/api/kandinskyAPI";

const Image = ({
  editing = true,
  imageNumber = 0,
  imageUri = "",
  borderData = {},
  type = "medium",
  onImagePicked = () => {},
}) => {
  const blocksContext = useContext(BlocksContext);
  const bordersContext = useContext(BordersContext);

  const [showModal, setShowModal] = useState(false);
  const [showAIModal, setShowAIModal] = useState(false);

  const [imageToGenerate, setImageToGenerate] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [kansinskyStyles, setKansinskyStyles] = useState([]);
  const [kLoading, setKLoading] = useState(false);
  const [kStyle, setKStyle] = useState("DEFAULT");
  const [generationid, setGenerationId] = useState("");

  const [prompt, setPrompt] = useState("");

  const [size, setSize] = useState({
    width: "400px",
    height: "300px",
  });
  const inputRef = useRef();

  const styles = {
    solidBorder: {
      borderColor: borderData.color,
      borderStyle: "solid",
      borderWidth: borderData.width,
      width: `${size.width + (borderData.width === "30px" ? 60 : 40)}px`,
    },
    gradientBorder: {
      borderWidth: "30px",
      borderStyle: "solid",
      borderImage: `linear-gradient(${borderData.color}, ${borderData.colorGradient}) 30`,
      borderRadius: "20px",
      width: `${size.width + 60}px`,
    },
  };

  const onImageClicked = () => {
    if (!editing) {
      return;
    }
    inputRef.current.click();
  };

  const onBorderChoose = (borderData) => {
    blocksContext.onImageBorderChanged(
      borderData.type === "noBorder" ? {} : borderData,
      imageNumber
    );
    setShowModal(false);
  };

  const onBorderColorChanged = (e) => {
    let border = { ...borderData };
    border.color = e.target.value;
    blocksContext.onImageBorderChanged(border, imageNumber);
  };
  const onBorderGradientColorChanged = (e) => {
    let border = { ...borderData };
    border.colorGradient = e.target.value;
    blocksContext.onImageBorderChanged(border, imageNumber);
  };

  useEffect(() => {
    setSize(getTypeSize(type));
  }, []);

  const onImageGenerated = () => {};

  const onImageGenerateClicked = async () => {
    setKLoading(true);

    // let generationData = await getKandinskyModels();

    let generationData = await postText2Image(prompt, kStyle);

    if (!generationData) {
      console.log("ERROR GENERATING IMAGE");
      setKLoading(false);
      return;
    }

    await checkImageGenerationStatus(generationData.data);
  };

  const checkImageGenerationStatus = async (uuid) => {
    let statusData = await getImageGenerationStatus(uuid);

    setKLoading(false);

    if (!statusData) {
      alert("Ошибка при генерации запроса. Попробуйте позже.");
      return;
    }

    setGeneratedImage(statusData);

    console.log(statusData);
  };

  const getStyles = async () => {
    let data = await getKandinskyPossibleStyles();
    setKansinskyStyles(data);
  };

  const getTypeSize = (type) => {
    if (type === "medium") {
      return { width: 300, height: 300 };
    } else if (type === "small") {
      return { width: 200, height: 200 };
    } else if (type === "big") {
      return { width: 400, height: 400 };
    } else if (type === "big-rect") {
      return { width: 533, height: 400 };
    } else if (type === "medium-rect") {
      return { width: 400, height: 300 };
    }
  };

  return (
    <div
      className={`d-flex justify-content-center position-relative rounded-3`}
      style={{
        ...(borderData.type === "image" && {
          width: `${size.width + 60}px`,
          border: borderData.border,
          borderImage: borderData.borderImage,
        }),
        ...(borderData.type === "solid" && styles.solidBorder),
        ...(borderData.type === "gradient" && styles.gradientBorder),
        ...(!borderData.type && { width: `${size.width}px` }),
      }}
    >
      {editing && (
        <div className="p-1 position-absolute image-instruments-container bg-light rounded border">
          <div className="d-flex justify-content-center flex-column">
            {blocksContext.loadingImage.imageLoading &&
            blocksContext.loadingImage.imageLoadingNumber === imageNumber ? (
              <img src="../../spinner.svg" width="30px" height="30px" />
            ) : (
              <a
                className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0 m-0 my-1"
                onClick={() => {
                  inputRef.current.click();
                }}
                title="Выбрать изображение"
              >
                <IoImageOutline size={16} />
              </a>
            )}
            {imageUri && (
              <a
                className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0 m-0 my-1"
                onClick={() => {
                  setShowModal(true);
                }}
                title="Рамка"
              >
                <IoScanOutline size={18} />
              </a>
            )}

            <a
              className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0 m-0 my-1"
              onClick={async () => {
                setShowAIModal(true);
                await getStyles();
              }}
              title="AI"
            >
              <IoSparkles size={18} />
            </a>
            {(borderData.type === "solid" ||
              borderData.type === "gradient") && (
              <input
                className="btn btn-sm btn-outline-dark p-1 editor-control-sm instrument-button"
                value={borderData.color}
                type="color"
                onChange={onBorderColorChanged}
                title="Основной цвет"
              />
            )}
            {borderData.type === "gradient" && (
              <input
                className="btn btn-sm btn-outline-dark p-1 editor-control-sm instrument-button"
                value={borderData.colorGradient}
                type="color"
                onChange={onBorderGradientColorChanged}
                title="Дополнительный цвет"
              />
            )}
          </div>
        </div>
      )}
      {imageUri !== "" ? (
        <div
          className={`bg-light ${!borderData.type && "rounded"}`}
          style={{
            width: "100%",
            paddingTop: `${(size.height / size.width) * 100}%`,
            backgroundImage: `url(${imageUri})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      ) : (
        <div
          className="d-flex img-thumbnail bg-light justify-content-center align-items-center"
          style={{
            position: "relative",
            width: "100%",
            paddingTop: `${(size.height / size.width) * 100}%`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <IoImageOutline
            size={80}
            style={{ position: "absolute", bottom: "40%" }}
          />
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ visibility: "hidden", position: "absolute" }}
        onChange={(e) => {
          if (!editing) {
            return;
          }
          blocksContext.onImagePicked(e, imageNumber);
        }}
      />
      <Modal show={showModal} size={"lg"}>
        <Modal.Header>
          <strong>Выберите рамку</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row row-cols-2 row-cols-lg-3 g-4">
            {bordersContext.map((border, i) => (
              <div
                className="col d-flex justify-content-center align-items-center"
                key={i}
              >
                <BorderTypeCard
                  borderData={border}
                  onClick={onBorderChoose}
                  imageUri={imageUri}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary rounded-pill"
            onClick={() => setShowModal(false)}
          >
            Закрыть
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAIModal} size={"lg"}>
        <Modal.Header>
          <strong>Генерация картинок</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowAIModal(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-4  g-4 p-4">
            {/* {bordersContext.map((border, i) => (
              <div
                className="col d-flex justify-content-center align-items-center"
                key={i}
              >
                <BorderTypeCard
                  borderData={border}
                  onClick={onBorderChoose}
                  imageUri={imageUri}
                />
              </div>
            ))} */}
            {kansinskyStyles.length > 0 && (
              <div className="d-block">
                <h5>Выберите стиль:</h5>
                <div>
                  <Form.Select
                    aria-label="Стили"
                    value={kStyle}
                    defaultValue={"DEFAULT"}
                    onChange={(e) => setKStyle(e.target.value)}
                  >
                    {kansinskyStyles.map((s, i) => (
                      <option value={s.name}>{s.title}</option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            )}
            {/* <div className="d-block">
              <h5>Выберите картинку:</h5>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (!editing) {
                    return;
                  }
                  setImageToGenerate(e);
                  console.log(e.target.files);
                }}
              />
            </div> */}
            {kLoading && <Spinner />}

            {generatedImage && (
              <div>
                <div>
                  <img
                    className="w-100 object-fit-cover"
                    src={"data:image/jpeg;base64, " + generatedImage}
                  />
                </div>
              </div>
            )}

            <div>
              <h5>Введите запрос:</h5>

              <textarea
                className="w-100 p-2"
                placeholder={`Например: "Диснеевский персонаж"`}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {kLoading ? (
            <Spinner />
          ) : (
            <button
              className={`btn ${
                prompt ? "btn-primary" : "btn-secondary text-black"
              } rounded-pill`}
              onClick={() => {
                onImageGenerateClicked();
              }}
              disabled={!prompt}
            >
              Сгенерировать
            </button>
          )}

          {generatedImage && (
            <button
              className="btn btn-success rounded-pill"
              onClick={() => {
                blocksContext.onImagePicked(type, imageNumber, generatedImage);
                setShowAIModal(false);
              }}
            >
              Готово
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Image;

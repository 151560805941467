import React, { createContext } from "react";

const BordersContext = createContext([
  {
    type: "noBorder",
    label: "Без рамки",
  },
  {
    type: "gradient",
    name: "gradient",
    label: "Рамка-градиент",
    color: "#f6b73c",
    colorGradient: "#4d9f0c",
  },
  {
    type: "solid",
    name: "singleColor20",
    label: "Однотонная рамка (20px)",
    width: "20px",
    color: "#f6b73c",
  },
  {
    type: "solid",
    name: "singleColor30",
    label: "Однотонная рамка (30px)",
    width: "30px",
    color: "#f6b73c",
  },
]);

export default BordersContext;

import React from "react";
import EditableText from "../editor/EditableText";

const BlockType4 = ({
  blockData = {},
  editing = true,
  onTextChanged = () => {},
}) => {
  return (
    <div className="row">
      <div className="col d-flex align-items-center flex-column">
        <div>
          <EditableText
            onTextChanged={onTextChanged}
            type="header"
            editing={editing}
            text={blockData.header}
            fontSize={blockData.headerSize}
            fontWeight={blockData.headerWeight}
            color={blockData.textColor}
            fontFamily={blockData.fontFamily}
            only
          />
        </div>
      </div>
    </div>
  );
};

export default BlockType4;

const GIFTS = [
  {
    name: "Сертификат на ресторан",
    description:
      "Позволяет насладиться ужином в изысканном ресторане на ваш выбор.",
    price: 5000,
    thumb: "/Ресторан.png",
  },
  {
    name: "Ваучер на спа-процедуры",
    description:
      "Предоставляет доступ к расслабляющим процедурам в лучшем спа-центре города.",
    price: 3000,
    thumb: "/СПА.png",
  },
  {
    name: "Депозит на банковском счете",
    description:
      "Создает депозит с высоким процентом для будущих финансовых нужд.",
    price: 10000,
    thumb: "/Депозит.png",
  },
  {
    name: "Электронный сертификат на онлайн-курс",
    description:
      "Открывает доступ к обучающему курсу по выбору ведущих экспертов в области.",
    price: 2000,
    thumb: "/Онлайн-курсы.png",
  },
  {
    name: "Бонусные баллы в онлайн-магазине",
    description:
      "Позволяет получить дополнительные скидки при покупках в интернет-магазине.",
    price: 1000,
    thumb: "/Онлайн магазин.png",
  },
  {
    name: "Электронный подарочный сертификат на кино",
    description:
      "Дает право посещения нескольких кинопремьер в кинотеатрах города.",
    price: 1500,
    thumb: "/Кинозал.png",
  },
  {
    name: "Ваучер на концертные билеты",
    description:
      "Обеспечивает вход на живой концерт любимого исполнителя в VIP-зону.",
    price: 8000,
    thumb: "/Концертный зал.png",
  },
  {
    name: "Электронный подарочный сертификат на отель",
    description:
      "Предоставляет проживание в роскошном отеле на выбор на несколько дней.",
    price: 12000,
    thumb: "/Отель.png",
  },
  {
    name: "Денежный купон для онлайн-платежей",
    description: "Позволяет оплатить различные услуги и товары через интернет.",
    price: 500,
    thumb: "/Деньги.png",
  },
  {
    name: "Бонусный сертификат на автомойку",
    description:
      "Предоставляет несколько бесплатных посещений автомойки для вашего автомобиля.",
    price: 2000,
    thumb: "/Автомойка.png",
  },
];

if (process.env.NODE_ENV === "production") {
  module.exports = {
    KANDINSKY_URL: "https://dev.bonfetta.com/api",
    GIFTS,
  };
} else {
  module.exports = {
    KANDINSKY_URL: "https://dev.bonfetta.com/api",
    GIGACHAT_SECRET:
      "ODgzNDFlMjUtYzViNi00M2FmLTk1OGQtYzIwNmI1NTgyMDU0OmZmNGU3OGI0LWNkZjItNGExZS1hZGZmLTY0NWZlMGU0YjM3OA==",
    GIFTS,
  };
}

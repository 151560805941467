import React, { createContext } from "react";

const BlocksContext = createContext({
  onImagePicked: () => {},
  onImageBorderChanged: (type, color) => {},
  onAudioPicked: () => {},
  onTextBlockTextChanged: () => {},
  onVideoPicked: () => {},
});

export default BlocksContext;

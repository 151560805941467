import React from "react";
import Footer from "../components/Footer";
import NavBar from "./../components/NavBar";

const MainLayout = ({ children }) => {
  return (
    <div className="App">
      <header>
        <NavBar />
      </header>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;

// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
//all endpoints here just for dev. need to store them as env variables later.
const firebaseConfig = {
  apiKey: "AIzaSyDikrJmPfypdWkEqI3gXAMirLQ9QblFU6E",
  authDomain: "bonfetta-52df5.firebaseapp.com",
  projectId: "bonfetta-52df5",
  storageBucket: "bonfetta-52df5.appspot.com",
  messagingSenderId: "1034606226168",
  appId: "1:1034606226168:web:aeedad8e0011c1f2e1a90d",
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default app;

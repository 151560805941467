import axios from "axios";
import FormData from "form-data";

import { KANDINSKY_URL } from "../config";

const AUTH_HEADER = {
  "X-Key": "Key " + "",
  "X-Secret": "Secret " + "KANDINSKY_SECRET ??",
};

export const getKandinskyPossibleStyles = async (text, temp = 0.85) => {
  try {
    let res = await axios.get("https://cdn.fusionbrain.ai/static/styles/api");

    let data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getImageGenerationStatus = async (uuid) => {
  try {
    console.log(uuid);
    let res = await axios.get(KANDINSKY_URL + "/kandinsky/status/" + uuid, {
      ...AUTH_HEADER,
    });

    let data = await res.data;

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getKandinskyModels = async () => {
  try {
    let res = await axios.get(KANDINSKY_URL + "/v1/models", {
      headers: {
        ...AUTH_HEADER,
      },
    });

    let data = await res.data;

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const postText2Image = async (query, style = "DEFAULT") => {
  try {
    const res = await axios.get(
      KANDINSKY_URL +
        "/kandinsky/generate/?message=" +
        query +
        "&model=4" +
        "&style=" +
        style,
      {
        headers: {},
      }
    );

    let data = await res.data;

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

import React from "react";
import { useHistory, useNavigate } from "react-router";
import app from "../data/firebase";
import { toast, ToastContainer } from "react-toastify";

const LoginForm = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { email, pass } = e.target.elements;
    try {
      await app.auth().signInWithEmailAndPassword(email.value, pass.value);
      navigate("/cards");
    } catch (error) {
      let message = "Неизвестная ошибка! Попробуйте позже!";
      if (error.code == "auth/user-not-found") {
        message = "Пользователь не найден. Зарегистрируйтесь!";
      }
      if (error.code == "auth/wrong-password") {
        message = "Неправильный пароль";
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <form
      className="d-flex container-fluid align-items-start flex-column mt-4"
      onSubmit={handleSubmit}
    >
      <label for="login">Логин</label>
      <div class="input-group mb-3">
        <input
          id="login"
          name="email"
          type="text"
          class="form-control"
          placeholder="Телефон/email"
          aria-label="Логин"
          aria-describedby="basic-addon1"
          required
        />
      </div>
      <label for="pass">Пароль</label>
      <div class="input-group mb-3">
        <input
          id="pass"
          name="pass"
          type="password"
          class="form-control"
          placeholder="Пароль"
          aria-label="Логин"
          aria-describedby="basic-addon1"
          required
        />
      </div>
      <div className="container-fluid p-0">
        <input
          type="submit"
          class="btn btn-lg btn-secondary w-100 primary-bg border-0"
          value="Вход"
        />
      </div>
      <div className="py-2">
        <span>Нет аккаунта? </span>
        <a href="/signup" className="text-reset">
          Создать!
        </a>
      </div>
      <ToastContainer />
    </form>
  );
};

export default LoginForm;

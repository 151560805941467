export const constants = {
  PRIMARY_COLOR: "#ff837a",
  FONTS: [
    "Helvetica",
    "Roboto",
    "Gill Sans",
    "Guerrilla",
    "Pacifico",
    "Caveat",
    "Amatic SC",
    "Bad Script",
    "Marck Script",
    "Pangolin",
    "Rampart One",
    "Lobster",
    "Underdog",
    "Orelega One",
    "Fortuna Gothic FlorishC",
    "EchoRevival",
    "RUSMost Wazted",
    "Josephina FlourishesC",
    "Vezitsa",
    "Ekaterina Velikaya Two",
    "Country Western Swing",
  ],
  FONT_SIZES: [14, 16, 18, 20, 22, 24, 28, 32, 36, 48, 52, 64],
  ENDPOINT: "https://bonfetta.com",
  BORDERS: [
    {
      type: "noBorder",
      label: "Без рамки",
    },
    {
      type: "gradient",
      name: "gradient",
      label: "Рамка-градиент",
      color: "#f6b73c",
      colorGradient: "#4d9f0c",
    },
    {
      type: "solid",
      name: "singleColor20",
      label: "Однотонная рамка (20px)",
      width: "20px",
      color: "#f6b73c",
    },
    {
      type: "solid",
      name: "singleColor30",
      label: "Однотонная рамка (30px)",
      width: "30px",
      color: "#f6b73c",
    },
  ],
};
